import $ from 'jquery';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../scss/index.scss';

$(() => {
    menuNavigation();
    scrollTop();
    searchForm();
    
    $('[data-toggle="tooltip"]').tooltip();
});

function menuNavigation(){

    $('#nav-toggle').click(function() {
        if($('.header__navigation--menu').hasClass('active')){
            $('.header__navigation--menu').removeClass('active');
            $(this).parents('.header__navigation').removeClass('active');
        }else{
            $('.header__navigation--menu').addClass('active');
            $(this).parents('.header__navigation').addClass('active');
        }
    });

    $('#nav-toggle').on('click', function() {
        this.classList.toggle('active');
    });
}

function scrollTop(){
    var scrollTop = $(".scrollTop");

    $(window).scroll(function() {
        var topPos = $(this).scrollTop();

        if (topPos > 100) {
            $(scrollTop).css("opacity", "1");
        } else {
            $(scrollTop).css("opacity", "0");
        }
    }); 

    $(scrollTop).click(function() {
        $('html, body').animate({
        scrollTop: 0
        }, 800);
        return false;
    });
}

function searchForm(){
    
    $('.search-bar span img').click(function(){
        $('#searchform').submit();
    });
}
